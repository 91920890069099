@import "../../index.css";

$base-gap: 4px;
$max-gap: 10;
$secondary: #F7D08A;
$dark-lightest: #263240;

@for $i from 1 through $max-gap {
    .gap-#{$i} {
        gap: #{$i * $base-gap};
    }
}

.newsletter-container {
    flex: 1 1 75%;

    .newsletter-form {
        display: flex;
        flex-direction: column;
    }

    h1 {
        margin-top: 10px;
        font-size: 38px;
        margin-bottom: 8px;
    }

    .sub-title {
        font-weight: 600;
    }
}

.newsletter-image-container {
    flex: 1 1 auto;
    position: relative;

    img {
        border-radius: 4px;
        mix-blend-mode: darken;
    }
}

.form-group {
    display: flex;
    
}

.form-control-group {
    display: block;
    flex-direction: column;
    gap: 5px;
    flex: 1 1 auto;
    flex-basis: 45%;
    position: relative;

    label {
        position: absolute;
        top: -15px;
        left: 0;
    }
}

.flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.wrap {
    flex-wrap: wrap;
}

.flex-column {
    flex-direction: column;
}

.flex-1 {
    flex: 1 1 auto;
}

.justify-space-between {
    justify-content: space-between    
}

.align-items-center {
    align-items: center;
}

.newsletter-submit {
    background-color: var(--secondary);
    padding: 8px 16px;
    border: none;
    color: var(--dark-light);

    &:hover {
        background-color: darken($secondary, 7%);
        cursor: pointer;
    }
}

.no-spam {
    font-size: 12px;
    margin-top: 12px;
    color: $dark-lightest;
    display: block;
}

@media screen and (max-width: 768px) {
    .newsletter-image-container {
        display: none;
    }

    .newsletter-container {
        width: 100%;
    }
}