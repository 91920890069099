:root {
  /* Base Theme */
  --primary: #F79F79;
  --secondary: #F7D08A;
  --tertiary: #E3F09B;
  --quaternary: #87B6A7;
  --quinary: #5B5941;

  /* Status Colors */
  --success: #22bb33;
  --danger: #bb2124;
  --warning: #f0ad4e;
  --info: #5bc0de;
  
  /* Neutral Colors */
  --black: #0d1116;
  --black-light: #131920;
  --black-lighter: #1e2833;
  --black-lightest: #263240;

  --white: #f8f8f8;
  --white-dark: #f4f4f5;
  --white-darker: #eaeaeb;
  --white-darkest: #d4d4d6;

}

body, html, :root, #root {
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Roboto', 'Oxygen', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(247,159,121);
  background: radial-gradient(ellipse at left top, rgba(247,159,121,0.8) 18%, rgba(240,230,225,1) 60%, rgba(244,244,247,1) 90%) no-repeat;
  box-sizing: border-box;
  height: 100%;
}

label {
  order: -1;
  padding-left: 5px;
  transition: all 0.3s ease-in;
  transform: translate(8px, 20px);
  pointer-events: none;
  font-size: 10px;
}

input {
  border-radius: 6px;
  border: 1px solid var(--primary);
  padding: 18px 12px 6px;
  width: 100%;
  box-sizing: border-box;
}

input:focus-visible {
  border: 1px solid var(--black-lightest);
  outline: 0;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  max-width: 1000px;
  margin: auto;
  padding: 0 20px;
  gap: 26px;
}

.error {
  border: 1px solid var(--danger);
}

.error-message {
  background-color: #bb212360;
  padding: 6px 12px;
  border-radius: 4px;
  color: var(--danger);
}